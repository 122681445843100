import Map from '@components/Map/Map';
import React from 'react';
import ReactDOM from 'react-dom';

const mapNode = document.querySelectorAll('.tryout-results-map');

if (mapNode.length > 0) {
  mapNode.forEach((node) => {
    if (node && node.dataset.tryoutCategory) {
      ReactDOM.render(
        <Map
          category={node.dataset.tryoutCategory || null}
          mainColor={node.dataset.tryoutBackground}
          seasons={
            node.dataset.tryoutSeason
              ? JSON.parse(node.dataset.tryoutSeason)
              : []
          }
          independentType={node.dataset.tryoutIndependentType || 'classic'}
          testimonyType={node.dataset.tryoutTestimonyType || 'classic'}
        />,
        node
      );
    }
  });
}
