import '@splidejs/splide/dist/css/splide.min.css';

import MapDisplay, { defaultProps } from '@components/TrialMaps/Map.js';
import React, { useEffect, useMemo, useState } from 'react';
import { flatten, size } from 'lodash-es';
import { queryClient, useAllTestResultPositions } from '@js/api';

import MapNav from '@components/TrialMaps/MapNav';
import MapOverlay from '@components/TrialMaps/MapOverlay';
import { QueryClientProvider } from 'react-query';
import SeasonSwitch from '@components/TrialMaps/SeasonSwitch.js';

const CATEGORIES_START_WITH_INDEPENDENT = ['1', '2'];

function findDefaultType(items = [], category = null) {
  if (
    category &&
    CATEGORIES_START_WITH_INDEPENDENT.includes(category) &&
    items.some((r) => r.type.startsWith('independent'))
  ) {
    return 'independent';
  }

  if (
    !items.some((r) => r.type.startsWith('testimony')) &&
    items.some((r) => r.type === 'intern')
  ) {
    return 'lg';
  } else if (
    !items.some((r) => r.type.startsWith('intern')) &&
    items.some((r) => r.type.startsWith('independent'))
  ) {
    return 'independent';
  } else if (
    !items.some((r) => r.type.startsWith('intern')) &&
    items.some((r) => r.type.startsWith('testimony'))
  ) {
    return 'testimony';
  }

  return 'lg';
}

function Map({
  center,
  zoom,
  category,
  product,
  mainColor,
  currentTryouts,
  seasons = [],
  testimonyType = 'classic',
  independentType = 'classic'
} = defaultProps) {
  const [type, setType] = React.useState('lg'); // "lg" | "independent" | "testimony"
  const [selectedTestResult, setSelectedTestResult] = React.useState(null);

  const defaultSeason = (seasons.find(item => item.selected === 1) || {}).value || "";

  const [season, setSeason] = React.useState(defaultSeason);

  const [searchData, setSearchData] = useState();

  const { data, isSuccess } = useAllTestResultPositions({
    category,
    variety: product,
    season,
    testimony_types: testimonyType,
    independentType
  });

  useEffect(() => {
    if (!window.TRIALS) return;

    const trialsFromSearch = JSON.parse(window.TRIALS);

    if (trialsFromSearch) {
      const intern = trialsFromSearch.filter((item) => item.type === 'intern');
      const testimony = trialsFromSearch.filter((item) =>
        item.type.startsWith('testimony')
      );
      const independent = trialsFromSearch.filter((item) =>
        item.type.startsWith('independent')
      );

      const searchedData = { intern, testimony, independent };
      setSearchData(searchedData);

      const flatArr = flatten(Object.values(searchedData));
      const type = findDefaultType(flatArr);
      setType(type);

      // if on search we click on a tryouts
      if (currentTryouts && currentTryouts.id && currentTryouts.type) {
        setSelectedTestResult(currentTryouts);
      }
    }
  }, [currentTryouts]);

  useEffect(() => {
    if (!data) return;

    const flatArr = flatten(Object.values(data));
    const type = findDefaultType(flatArr, category);
    setType(type);
  }, [category, data]);

  let values = data;

  if (searchData) {
    values = searchData;
  }

  const results = useMemo(() => {
    if (type === 'lg') {
      return data?.['intern'];
    }
    if (type === 'independent') {
      return data?.['independent'];
    }
    if (type === 'testimony') {
      return data?.['testimony'];
    }

    return [];
  }, [type, data]);

  if (!results) return null;

  let internLabel = 'Essais LG & Partenaires';

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <MapNav
          results={results}
          type={type}
          handleClick={setType}
          hasIndependent={size(values?.['independent'])}
          hasTestimony={size(values?.['testimony'])}
          hasIntern={size(values?.['intern'])}
          category={category}
          internLabel={internLabel}
        />
        {Array.isArray(seasons) && seasons.length > 1 ? (
          <SeasonSwitch
            seasons={seasons}
            selected={season}
            handleChange={(opt) => {
              setSeason(opt);
            }}
            withDefault={category !== '6'}
          />
        ) : null}
      </div>
      <MapDisplay
        results={results}
        season={season}
        isSuccess={isSuccess}
        type={type}
        center={center}
        zoom={zoom}
        handleClick={(id, type) => {
          setSelectedTestResult({ id, type });
        }}
      />
      {selectedTestResult ? (
        <>
          <div className="MapOverlay-background"></div>
          <MapOverlay
            id={selectedTestResult.id}
            type={selectedTestResult.type}
            category={category}
            product={product}
            handleClose={() => setSelectedTestResult(false)}
            mainColor={mainColor}
          />
        </>
      ) : null}
    </div>
  );
}

function MapWrapper(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <Map {...props} />
    </QueryClientProvider>
  );
}

export default MapWrapper;
